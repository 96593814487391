import React from 'react'
import "./portfolio.css"
import IMG1 from "../../assets/Marinawak.jpg";
import IMG2 from "../../assets/Scubalibre.jpg";


const data = [
  {
    id:1,
    image: IMG1,
    title: "Marinawak",
    link:"https://marinawak.herokuapp.com/",
    github: "https://github.com/GothTheFunk/-Marinawak"
  },
  {
    id:2,
    image: IMG2,
    title: "Scubalibre",
    link: "http://www.scubalibre.io/",
    github: "https://github.com/Ahlonko1/Scuba_Libre"
  }
]

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, link, github}) =>{
            return(
              <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className='portfolio__item-cta'>
              <a href={github} className='btn' target='_blank' rel='noreferrer'>Github</a>
              <a href={link} className='btn btn-primary' target='_blank' rel='noreferrer'>Website</a>
              </div>
            </article>
            )
          })
        }

      </div>

    </section>
  )
}

export default Portfolio
