import React from 'react'
import "./header.css"
import CTA from "./CTA"
import ME from "../../assets/AK.jpg"
import HeaderSocial from "./HeaderSocials"
import { CgScrollV } from "react-icons/cg";

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hey,</h5>
        <h1>I'm Ahlonko Kowouvi</h1>
        <h5 className="text-light"> I am a fullstack web developer</h5>
        <CTA />

        <HeaderSocial />

        <div className="me">
          <img src={ME} alt="me" />
        </div>


        {/* <a href="#contact" className='scroll__down'> <CgScrollV/></a> */}
      </div>
    </header>
  )
}

export default Header
