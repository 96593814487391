import React from 'react'
import "./testimonials.css"
import AVTR1 from "../../assets/Bouv.jpg";
import AVTR2 from "../../assets/Guilhem2.jpg";

// import Swiper core and required modules
import {Pagination} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data = [
  {
    avatar: AVTR1,
    name: "Thomas Bouvier",
    review:"C'est mon reuf, il est chaud lahuiss! Je suis refait du portfolio qu'il a fait pour mon entreprise privée."
  },
  {
    avatar: AVTR2,
    name: "Guilhem Hidalgo",
    review:"C'est de la frappe ce qu'il fait! Je suis content de lui avoir confié la réalisation de mon site de vente en ligne."
  }
]

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
      {
        data.map(({avatar, name, review}, index) => {
          return(
            <SwiperSlide key={index} className='testimonial'>
            <div className="client__avatar">
              <img src={avatar} />
            </div>
              <h5 className='client__name'>{name}</h5>
              <small className='client__review'>{review}</small>
          </SwiperSlide>
          )
        })
      }
      </Swiper>
    </section>
  )
}

export default Testimonials
