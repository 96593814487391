import React from 'react'
import "./services.css"
// import {BiCheck} from "react-icons/bi";

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">

      <article className='service'>
          <div className="service__head">
            <h3>Web Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              {/* <BiCheck className='service__list-icon' /> */}
              <p>Your website will work perfectly on any platform, mobile or desktop.</p>
            </li>
            <li>
              <p>Your target audience will find your site and services on Google.</p>
            </li>

          </ul>
        </article>

        <article className='service'>
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>

          <ul className='service__list'>
            <li>
              {/* <BiCheck className='service__list-icon' /> */}
              <p>Designed and optimized to load your site faster, providing excellent user experience.</p>
            </li>


          </ul>
        </article>




      </div>

    </section>
  )
}

export default Services
