import React from 'react'
import "./about.css"
import ME from "../../assets/Kokolombie.JPG"
import {FaAward} from "react-icons/fa";
import {FiUsers} from "react-icons/fi";
import {VscFolderLibrary} from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME} alt=""/>
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>In professional retraining since April 2022</small>
            </article>

            <article className="about__card">
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>In progress</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>2</small>
            </article>
          </div>

          <p>What makes me as happy as Larry? When my code works!!
After 8 years in Accounting, Tax and Finance,  I recently endeavoured in the tech world by following the Web development bootcamp at Le Wagon and enjoyed it! Indeed coding suits my analytical background and my thirst to always keep learning as well as triggering my inner creativity.
Today, I have SQL, Ruby, Ruby on Rails, Bootstrap, CSS, HTML, Git/Github and JavaScript under my belt. I am therefore looking for any challenging opportunity that will help my next professional chapter start with a bang.
Feel free to reach out to me.</p>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>

        </div>

      </div>

    </section>
  )
}

export default About
